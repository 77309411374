import React from 'react'
import { NavLink } from "react-router-dom"
import styled from 'styled-components';
import HomeImage from "../../images/Home.png"

import "./Header.scss"

const LINKS = [
    {
        title: 'home',
        url: '/app'
    },
    {
        title: 'use cases',
        url: '/app/use-cases'
    },
    {
        title: 'pricing',
        url: '/app/pricing'
    },
    {
        title: 'how it works',
        url: '/app/how-it-works'
    },
    {
        title: 'verify a filing',
        url: '/app/verify'
    },
]

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
`;

const Logo = styled.div`
    background: url('./assets/logo.png') no-repeat center / contain;
    width: 220px;
    height: 90px;
    cursor: pointer;
`;

const LinksWrapperDesktop = styled.div`
    a {
        margin: 0 20px;
        text-transform: uppercase;

        &.active {
            font-weight: bold;
        }
    }

    img {
        width: 22px;
    }

    @media (max-width: 650px) {
        display: none;
    }
`;

const Header = () => {
    return (
        <Wrapper className="navBar">
            <Logo onClick={() => window.location.replace('/app') }/>

            <LinksWrapperDesktop>
            {LINKS.map(({ title, url }) => (
                <NavLink activeClassName="active" key={url}
                    to={url}>
                        {url === '/app'
                            ? <img src={HomeImage} alt="Home"/>
                            : <span>{title}</span>}
                    </NavLink>
            ))}
            </LinksWrapperDesktop>

        </Wrapper>
    )
}

export default Header;
