import React from 'react'
import { Link } from "react-router-dom"
import "./Footer.scss"

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container" >
                <ul >
                    <li>
                        <a className="footerLink__pressKit">PRESS KIT</a>
                    </li>
                    <li>
                        <Link className="footerLink" to="/impressum">IMPRESSUM</Link>
                    </li>
                    <li>
                        <Link className="footerLink" to="/termsandconditions" target="_blank">TERMS & CONDITIONS</Link>
                    </li>
                    <li>
                        <Link className="footerLink" to="/dataprotection" target="_blank">DATA PROTECTION</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
