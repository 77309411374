import {useState,useEffect} from "react"
import styled from 'styled-components';
import classnames from "classnames"
import {
    getAllUsers, getAllCoupons, setNewCoupon, removeCoupon
} from '../../api'
import {
    Container, Row, Col, Nav, NavItem, NavLink, TabPane,
    TabContent, Table, Badge, FormGroup, Label, Input, Button
} from 'reactstrap';
import { Homepage, UseCases, HowItWorks, VerifyFile, Pricing } from '../PageContent'

import "./style.scss"

const DEFAULT_COUPON = { couponName: '', discount: '' }
const NAV_LINKS = [
    {
        id: '1',
        title: 'User List'
    },
    {
        id: '2',
        title: 'Apply Coupon'
    },
    {
        id: '3',
        title: 'Homepage Content'
    },
    {
        id: '4',
        title: 'Use Cases Content'
    },
    {
        id: '5',
        title: 'Pricing Content'
    },
    {
        id: '6',
        title: 'How It Works Content'
    },
    {
        id: '7',
        title: 'Verify File Content'
    },
]

const Wrapper = styled.div`
    background-color: #fff;
    width: 100%;
    max-width: 1200px;
    margin: 5% auto 5%;
    padding: 20px;
    border-radius: 15px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const CouponWrapper = styled.div`
    padding: 20px;

    >button {
        margin-right: 15px;
        font-size: 20px;
        font-weight: bold;
    }

    >div{
        padding-top: 20px;
    }
`;

const AdminSettings = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [users, setUsers] = useState([])
    const [coupons, setCoupons] = useState([])
    const [couponDetails, setCouponDetails] = useState(DEFAULT_COUPON)

    useEffect(() => {
        getAllUsers()
        .then(({ data }) => setUsers(data))

        getAllCoupons()
        .then(({ data }) => setCoupons(data))
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const handleCouponDetails = (key, value) => {
        setCouponDetails({ ...couponDetails, [key]: value })
    }

    const addNewCoupon = () => {
        const couponCode = `${couponDetails.couponName}-${couponDetails.discount}`;

        setNewCoupon({ couponCode })
        .then((isVerified) => {
            if (isVerified) {
                alert('Coupon code has set.')
                setCouponDetails(DEFAULT_COUPON)
                getAllCoupons().then(({ data }) => setCoupons(data))
            } else {
                alert('Something wrong, try again.')
            }
        })
    }

    const handleRemoveCoupon = (code) => {
        removeCoupon(code)
        .then((isVerified) => {
            if (isVerified) {
                alert('Coupon code has been removed.')
                getAllCoupons().then(({ data }) => setCoupons(data))
            } else {
                alert('Something wrong, try again.')
            }
        })
    }

    const isBtnDisabled = !couponDetails.couponName || !couponDetails.discount

    return(
        <Wrapper>
            <Title>Admin panel</Title>

            <Nav tabs>
                {NAV_LINKS.map(({ id,title }) => (
                    <NavItem className={classnames({ active: activeTab === id })} onClick={() => { toggle(id); }}>
                        <NavLink><h3>{title}</h3></NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(({ _id, status, name, email }) => (
                                <tr key={_id}>
                                    <th scope="row">{_id}</th>

                                    <th scope="row">
                                        {status === 'Pending'
                                        ? (<Badge color="secondary">Pending</Badge>)
                                        : (<Badge color="success">Active</Badge>)}
                                    </th>

                                    <th scope="row">{name}</th>
                                    <th scope="row">{email}</th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TabPane>

                <TabPane tabId="2" style={{ maxWidth: '320px', padding: '30px 20px'}}>

                    <Container>
                        <Row>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <Label for="coupname">Coupon Name</Label>
                                    <Input type="text" id="coupname" value={couponDetails.couponName}
                                        onChange={({target}) => handleCouponDetails('couponName', target.value)}/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="discount">Coupon Discount (%)</Label>
                                    <Input type="number" id="discount" value={couponDetails.discount}
                                        onChange={({target}) => handleCouponDetails('discount', target.value)}/>
                                </FormGroup>

                                <Button onClick={addNewCoupon} disabled={isBtnDisabled}>Apply</Button>
                            </Col>
                            <Col xs="12" md="8">
                                <CouponWrapper>
                                    {coupons.map(({ code }, key) =>
                                        (<Button key={key} onClick={()=>handleRemoveCoupon(code)}>{code}</Button>))}
                                    <div>Click on a coupon to remove.</div>
                                </CouponWrapper>
                            </Col>
                        </Row>
                    </Container>

                </TabPane>

                <TabPane tabId="3">
                    <Homepage/>
                </TabPane>
                <TabPane tabId="4">
                    <UseCases/>
                </TabPane>
                <TabPane tabId="5">
                    <Pricing/>
                </TabPane>
                <TabPane tabId="6">
                    <HowItWorks/>
                </TabPane>
                <TabPane tabId="7">
                    <VerifyFile/>
                </TabPane>

            </TabContent>
        </Wrapper>
    )
}

export default AdminSettings
