import axios from "axios"
import { API_URL } from '../config'

// Users API
export const verifyEMail = ({ name, email }) => {
    const body = { name, email }

    return axios.post(`${API_URL}/api/auth/signup`, body)
        .then((response) => {
            return response.status === 200;
        });
}

export const verifyConfirmationCode = (confirmationCode) => {

    return axios.get(`${API_URL}/api/auth/verify`, { params: { confirmationCode } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

export const verifyCouponCode = (couponCode) => {

    return axios.get(`${API_URL}/api/coupon/verify`, { params: { couponCode } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

export const resendConfirmationEmail = (email) => {

    return axios.get(`${API_URL}/api/auth/re-verify`, { params: { email } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

// function created by kashif
export const sendPdfEmail = ({ email, base64Pdf, userName , fileName, fileHash, transactionHash }) => {
    const body = { userEmail:email , base64Pdf, userName , fileName, fileHash, transactionHash}
    return axios.post(`${API_URL}/api/auth/send-file-in-email`, body)
        .then((response) => {
            return response.status === 200;
        });
}

export const verifyCertificate = (filterData) => {

    return axios.get(`${API_URL}/api/auth/verify-document`, { params: { filterData } })
        .then((response) => {
            return response;
        })
        .catch(err => { });
}
