import { useState } from 'react'
import FillForm from './FillForm'
import Payment from './Payment/Payment'
import Breadcrumbs from './Breadcrumbs'
import "./style.scss"
import Certificate from './original-Certificate/Certificate'

const ActionForm = () => {
    const [activeStage, setActiveStage] = useState(0)
    const [email, setEmail] = useState("")
    // modifed by kashif
    const [file, setFile] = useState({})
    const [name, setName] = useState("")
    const [fileData, setFileData] = useState({
        name:"",
        file_name:"",
        file_hash:"",
        transactionHash:""
    });

    return(
        <div className="actionFormContainer" >
            <div className="icon-arrow"/>

            <Breadcrumbs activeStage={activeStage}/>

            <div className="actionFormContainer__form" >
                {activeStage === 0 && <FillForm setActiveStage={setActiveStage} email={email} setEmail={setEmail} setFile={setFile} setName={setName} file={file} name={name}/>}
                {activeStage === 1 && <Payment setActiveStage={setActiveStage} email={email} name={name} file={file} setFileData={setFileData}/>}
                {activeStage === 2 && <Certificate fileData={fileData} email={email} />}
            </div>

        </div>
    )
}


export default ActionForm
