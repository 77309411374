import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Asset from './Illu.png'
import MainButton from '../MainButton'
import { ButtonWrapper } from '../HomepageText'
import { getPageContent } from '../../api/cms'

const Illustration = styled.div`
    width: 100%;
    max-width: 750px;
    height: 180px;
    margin: 0 auto 30px;
    background-image: url(${Asset});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const Text = styled.p`
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 40px;
    white-space: break-spaces;
`;

const HowItWorks = () => {
    const [content, setContent] = useState({})

    useEffect(() => {
        getPageContent({ page: 'how-it-works' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    return (
        <div className="WelcomeTextContainer" >

            <Illustration/>

            <Text dangerouslySetInnerHTML={{ __html: content.headlineFirst }}/>

            <ButtonWrapper>
                <MainButton text="use cases" to="/use-cases"/>
                <MainButton text="GET STARTED"/>
            </ButtonWrapper>

        </div>
    )
}

export default HowItWorks
