import styled from 'styled-components';
import MainButton from '../MainButton'

const Title = styled.div`
    font-size: 2.6vw;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-family: "ConneqtRegular";

    @media (max-width: 600px) {
    font-size: 20px;
    }
`;
const Text = styled.div`
    font-size: 18px;
`;
const TextGroup = styled.div`
    margin-bottom: 45px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;

    a {
        text-transform: uppercase;
    }
`;

const BottomText = styled.div`
    color: #73dddd;
    font-size: 20px;
    padding-top: 75px;
`;

const HomepageText = ({ content }) => {
    return (
        <div className="WelcomeTextContainer" >
            <TextGroup>
                <Title>{content.headlineFirst}</Title>
                <Text>
                    {content.textFirst}
                </Text>
            </TextGroup>
            <TextGroup>
                <Title>{content.headlineSecond}</Title>
                <Text>
                    {content.textSecond}
                </Text>
            </TextGroup>
            <TextGroup>
                <Title>{content.headlineThird}</Title>
                <Text>
                    {content.textThird}
                </Text>
            </TextGroup>

            <ButtonWrapper>
                <MainButton text="HOW IT WORKS" to="/how-it-works"/>
                <MainButton text="GET STARTED"/>
            </ButtonWrapper>

            <BottomText>No Subscription - No Registration - Just Pay As You Go.</BottomText>

        </div>
    )
}

export default HomepageText
